import { trueColor } from '../components/Drawer/Satellites/Sentinel/sentinel-default-evalscripts';
import { SentinelFeature } from '../store/Map/Sentinel/model';
import Api from './api';
import Constants from '../constants';
import SentinelUtil from '../lib/sentinel-util';
import { LatLngBounds } from 'leaflet';
import { CancelTokenSource } from 'axios';
import axios from 'axios';

export const DEFAULT_FEATURE_LIMIT = 20;
const BASE_URL = Constants.API_CF_SENTINEL_URL;
const LANDSAT_RESOLUTION = 30;

const requestCancelTokens = new Map<string, CancelTokenSource>();

export default class ApiCfSentinel extends Api {
    public static async searchSentinelFeatures({
        bounds,
        dateFrom,
        dateTo,
        satellites,
        limit,
        evalScript = trueColor,
        userSelectedResolution,
    }: GetSentinelCatalogParams): Promise<SentinelFeature[]> {
        const bbox = [
            bounds.getSouthWest().lng,
            bounds.getSouthWest().lat,
            bounds.getNorthEast().lng,
            bounds.getNorthEast().lat,
        ];

        const startDate = dateFrom.toISOString().split('T')[0];
        const endDate = dateTo.toISOString().split('T')[0];

        const requestPayload = {
            bbox,
            timeRange: {
                from: `${startDate}T00:00:00Z`,
                to: `${endDate}T23:59:59Z`,
            },
            satellites: satellites,
            limit: limit || DEFAULT_FEATURE_LIMIT,
        };

        const response = await this.axios.post(`${BASE_URL}/search`, requestPayload);
        const searchDto = response.data as SentinelSearchDTO;

        const catalogItemsByDate: Record<string, SentinelFeature[]> = {};
        const sentinelPixelResolution = userSelectedResolution ?? SentinelUtil.pixelResolutionForBounds(bounds);

        searchDto.items.forEach((item) => {
            const sentinelFeature: SentinelFeature = {
                id: item.id,
                bbox: bounds,
                date: item.datetime,
                previewUrl: '',
                highResolutionPreviewUrl: undefined,
                collection: item.satellite,
                evalScript: evalScript,
                cloudCover: item.cloudCover,
                satellite: item.satellite,
                instruments: item.instruments,
                bands: item.bands,
                constellation: item.constellation,
                resolution: SentinelUtil.isLandsatSatellite(item.satellite)
                    ? LANDSAT_RESOLUTION
                    : sentinelPixelResolution ?? 0,
            };
            const dateKey = item.datetime.split('T')[0];
            if (!catalogItemsByDate[dateKey]) {
                catalogItemsByDate[dateKey] = [];
            }
            catalogItemsByDate[dateKey].push(sentinelFeature);
        });

        // Merge the catalog items and calculate average cloud cover
        const mergedCatalogItems: SentinelFeature[] = Object.entries(catalogItemsByDate).map(([date, items]) => {
            const averageCloudCover = SentinelUtil.calculateAverageCloudCover(items);
            return {
                ...items[0],
                date,
                cloudCover: averageCloudCover,
            };
        });

        // Sort the merged items by date in descending order
        const sortedCatalogItems = mergedCatalogItems.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );

        return sortedCatalogItems;
    }

    public static async updateSentinelImage(
        feature: SentinelFeature,
        evalOption: EvalScript,
        width: number,
        height: number,
        date = feature.date,
        previewMode?: SupportedPreviewMode,
        contentType?: SupportedContentType,
        quality?: number,
        returnKey?: (key: string) => void,
        isThumbnail?: boolean
    ): Promise<GenerateImageResponse> {
        // To display all the images available for a specific day we need to use the start and end of the day
        const formattedDate = date.split('T')[0];
        const dateFrom = `${formattedDate}T00:00:00Z`;
        const dateTo = `${formattedDate}T23:59:59Z`;
        const bbox = SentinelUtil.isLandsatSatellite(feature.satellite)
            ? SentinelUtil.convertLatLngToBbox(feature.bbox)
            : SentinelUtil.convertLatLngToNumberArray(feature.bbox);
        const requestBody: GenerateImageBody = {
            bbox: bbox,
            timeRange: {
                from: dateFrom,
                to: dateTo,
            },
            satellite: evalOption.sentinelSatelliteType,
            evalscript: evalOption.script,
            evalscriptName: evalOption.name,
            width: width,
            height: height,
            contentType: contentType ?? 'image/png',
            quality: quality,
        };
        if (previewMode) {
            requestBody.previewMode = previewMode;
        }

        if (evalOption?.processing) {
            requestBody.processing = evalOption.processing;
        }

        const { imageUrl, error } = await this.fetchSentinelImage(requestBody, returnKey, isThumbnail);
        if (error) {
            return { imageUrl: '', error };
        }

        return { imageUrl: imageUrl ?? '' };
    }

    public static async generateImageThumbnail(
        feature: SentinelFeature,
        evalOption: EvalScript,
        setApiImageKey?: (key: string) => void
    ): Promise<string> {
        const pixelResolution = SentinelUtil.pixelResolutionForBounds(feature.bbox);
        const smallestPreviewSizeForRes = SentinelUtil.getSmallestPreviewByPixelResolution(pixelResolution);

        const { imageUrl, error } = await ApiCfSentinel.updateSentinelImage(
            feature,
            evalOption,
            smallestPreviewSizeForRes,
            smallestPreviewSizeForRes,
            feature.date,
            'TILE_PREVIEW',
            'image/jpeg',
            20,
            setApiImageKey,
            true
        );

        if (error) {
            console.error('Failed to fetch preview image:', error);
        }

        return imageUrl ?? '';
    }

    public static async updateSentinelPreviewImage(
        feature: SentinelFeature,
        evalOption: EvalScript,
        date = feature.date,
        previewMode?: SupportedPreviewMode
    ): Promise<string> {
        const pixelResolution = SentinelUtil.pixelResolutionForBounds(feature.bbox);
        const smallestPreviewSizeForRes = SentinelUtil.getSmallestPreviewByPixelResolution(pixelResolution);
        const { imageUrl, error } = await ApiCfSentinel.updateSentinelImage(
            feature,
            evalOption,
            smallestPreviewSizeForRes,
            smallestPreviewSizeForRes,
            date,
            previewMode ?? 'EXTENDED_PREVIEW'
        );

        if (error) {
            console.error('Failed to fetch preview image:', error);
        }

        return imageUrl ?? '';
    }

    public static async getSentinelTiffDownloadImage(
        feature: SentinelFeature,
        width: number,
        height: number
    ): Promise<string | undefined> {
        const formattedDate = feature.date.split('T')[0];
        const dateFrom = `${formattedDate}T00:00:00Z`;
        const dateTo = `${formattedDate}T23:59:59Z`;
        const bbox = SentinelUtil.isLandsatSatellite(feature.satellite)
            ? SentinelUtil.convertLatLngToBbox(feature.bbox)
            : SentinelUtil.convertLatLngToNumberArray(feature.bbox);
        const requestBody: GenerateImageBody = {
            bbox: bbox,
            timeRange: {
                from: dateFrom,
                to: dateTo,
            },
            satellite: feature.collection,
            evalscript: feature.evalScript?.script.replace('output: { bands: 4 }', 'output: { bands: 3 }'), // Remove the alpha band so it downloads in color
            evalscriptName: feature.evalScript?.name,
            width: width,
            height: height,
            contentType: 'image/tiff',
        };

        const result = await this.fetchSentinelImage(requestBody);

        if (result.error) {
            console.log('Failed to fetch high resolution preview image:', result.error);
        }
        return result.imageUrl ?? undefined;
    }

    private static async fetchSentinelImage(
        body: GenerateImageBody,
        returnKey?: (key: string) => void,
        isThumbnail = false
    ): Promise<GenerateImageResponse> {
        const requestKey = JSON.stringify({
            bbox: body.bbox,
            from: body.timeRange.from,
            to: body.timeRange.to,
            satellite: body.satellite,
            width: body.width,
            height: body.height,
            body: body.evalscript,
            date: new Date().toISOString(),
        });
        if (returnKey) {
            returnKey(requestKey);
        }
        if (requestCancelTokens.has(requestKey)) {
            requestCancelTokens.get(requestKey)?.cancel('Cancelled due to a new request for the same image.');
        }

        const cancelTokenSource = axios.CancelToken.source();
        requestCancelTokens.set(requestKey, cancelTokenSource);

        const config = {
            headers: {
                Accept: body.contentType,
                'Content-Type': 'application/json',
            },
            cancelToken: cancelTokenSource.token,
            responseType: 'arraybuffer',
        };

        try {
            const url = isThumbnail ? `${BASE_URL}/generate-thumbnail` : `${BASE_URL}/generate-image`;
            const response = await this.axios.post(url, body, config);
            const imageUrl = URL.createObjectURL(new Blob([response.data], { type: body.contentType }));

            requestCancelTokens.delete(requestKey);

            return { imageUrl };
        } catch (error) {
            return { imageUrl: '', error: error.message || 'An unknown error occurred while fetching the image.' };
        }
    }

    public static clearAllCancelTokens(reason?: string) {
        requestCancelTokens.forEach((cancelTokenSource, key) => {
            cancelTokenSource.cancel(reason ?? 'Clearing all cancel tokens');
            requestCancelTokens.delete(key);
        });
    }

    public static cancelToken(key: string, reason?: string) {
        requestCancelTokens.get(key)?.cancel(reason ?? 'Cancelling the request');
        requestCancelTokens.delete(key);
    }

    public static async postSentinelShortUrl(feature: SentinelFeature): Promise<string | { error: string }> {
        const requestBody: SentinelShortUrlDTO = {
            id: feature.id as string,
            bbox: SentinelUtil.convertLatLngToNumberArray(feature.bbox),
            date: feature.date,
            evalscript: feature.evalScript,
            satellite: feature.collection,
        };
        const response = await this.axios.post(`${BASE_URL}/short-url`, requestBody);
        const { id } = response.data;
        return id;
    }

    public static async getSentinelShortUrl(id: string): Promise<SentinelShortUrlDTO | undefined> {
        const response = await this.axios.get(`${BASE_URL}/short-url/${id}`);
        return response.data;
    }
}

export interface SentinelBandDTO {
    name: string;
    commonName?: string;
    centerWavelength: number;
    fullWidthHalfMax: number;
}

export interface SentinelSearchItemDTO {
    id: string;
    bbox: number[];
    datetime: string;
    cloudCover: number;
    instruments: string[];
    bands: SentinelBandDTO[] | [];
    satellite: string;
    constellation: string;
}

export interface SentinelSearchDTO {
    items: SentinelSearchItemDTO[];
}

export interface GetSentinelCatalogParams {
    bounds: LatLngBounds;
    dateFrom: Date;
    dateTo: Date;
    satellites: string[];
    limit?: number;
    evalScript?: EvalScript;
    userSelectedResolution?: number;
}

export interface SentinelShortUrlDTO {
    id: string;
    bbox: number[];
    date: string;
    evalscript: EvalScript;
    satellite: string;
}

export interface TimeRange {
    from: string;
    to: string;
}

export type SupportedContentType = 'image/png' | 'image/tiff' | 'image/jpeg';
export type SupportedPreviewMode = 'DETAIL' | 'TILE_PREVIEW' | 'EXTENDED_PREVIEW' | 'PREVIEW';

export interface GenerateImageBody {
    bbox: number[];
    timeRange: TimeRange;
    satellite: string;
    evalscript: string;
    evalscriptName: string;
    width: number;
    height: number;
    contentType: SupportedContentType;
    previewMode?: SupportedPreviewMode;
    processing?: Processing;
    quality?: number;
}

export interface Processing {
    orthorectify?: string;
    backCoeff?: string;
    demInstance?: string;
    [key: string]: string | undefined;
}
export interface EvalScript {
    name: string;
    script: string;
    sentinelSatelliteType: string;
    processing?: Processing | undefined;
    description?: string;
    legend?: string;
    author?: string;
}

export interface GenerateImageResponse {
    imageUrl: string;
    error?: string;
}
